import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule, HttpHeaders} from "@angular/common/http";
import {RouterModule, Routes} from "@angular/router";

import { AppComponent } from './app.component';
import { ThreeComponent } from './three/three.component';
import { Lista3dComponent } from './lista3d/lista3d.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LinkModule } from './link/link.module';
import { LinkComponent } from './link/link.component';
import { HomeModule } from './home/home.module';
import { ImagesGeneratorComponent } from './images-generator/images-generator.component';
import { Simple3dComponent } from './simple3d/simple3d.component';
import { LoaderComponent } from './loader/loader.component';


const routes:Routes =[

  //{path:"home", component: Lista3dComponent },
  {path:"home", component: Simple3dComponent },

   {path:"", redirectTo:"/home", pathMatch:"full"},

   {path:"simple3d", component: Simple3dComponent },
  {
    path: 'present',
    loadChildren: () => import('./present/present.module').then(mod => mod.PresentModule)
  },
  {
    path: 'home2',
    loadChildren:
      () => import('./doubled-carousel/doubled-carousel.module').then(
        mod => mod.DoubledCarouselModule
      )
  },
  {
    path: 'gallery-carousel',
    loadChildren: () => import('./gallery/gallery.module').then(mod => mod.GalleryModule)
  },
  {
    path: 'link-comp',
    component: LinkComponent
  }, /**/
  //{path:"", redirectTo:"/simple3d", pathMatch:"full" }
]

@NgModule({
  declarations: [
    AppComponent,
    ThreeComponent,
    Lista3dComponent,
    ImagesGeneratorComponent,
    Simple3dComponent,
    LoaderComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    MaterialModule,
    CarouselModule,
    LinkModule,
    //HomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
