import {
  ElementRef,
  Injectable,
  NgZone,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import * as THREE from 'three';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import {
  Collada,
  ColladaLoader,
} from 'three/examples/jsm/loaders/ColladaLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  CarouselComponent,
  OwlOptions,
  SlidesOutputData,
} from 'ngx-owl-carousel-o';
import {
  Material,
  MeshBasicMaterial,
  MeshNormalMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
  MeshToonMaterial,
} from 'three';
import { Observable } from 'rxjs';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderComponent } from './loader/loader.component';

@Injectable({
  providedIn: 'root',
})
export class ThreeService implements OnDestroy {
  private angularRenderer: Renderer2;
  public canvas: ElementRef<HTMLCanvasElement>;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  public scenes: THREE.Scene[];
  private light: THREE.AmbientLight;
  private controls: OrbitControls;
  private loadingManager: THREE.LoadingManager;

  private lastElementOrbitControl: HTMLElement;

  private actualindex: number = 0;

  public is3dloaded: boolean = false;
  public loadingProgressText: string = '';

  private cube: THREE.Mesh;
  private box: THREE.BoxHelper;

  private frameId: number = null;

  public lista: ListaFiles;
  inCarrousel: boolean;
  snackref: any;

  constructor(
    private ngZone: NgZone,
    renderFactory: RendererFactory2,
    private http: HttpClient,
    private _snackBar: MatSnackBar
  ) {
    this.angularRenderer = renderFactory.createRenderer(null, null);
    this.loadingManager = new THREE.LoadingManager();
  }

  ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  LoadListFiles(): Observable<string> {
    return new Observable((observer) => {
      this.http
        .get<ListaFiles>('assets/lista3d.json')
        .subscribe((data: any) => {
          this.lista = JSON.parse(data);
          console.log('3d list loaded ');
          observer.next('3d list loaded ');
          observer.complete();
        });
    });
  }

  Lista3dMock: any = {
    items: [
      {
        id: 'slide-1',
        url: 'assets/abb_irb52_7_120.dae',
      },
      {
        id: 'slide-2',
        url: 'assets/raf22031.dae',
      },
    ],
  };

  shoe3d(objeto: Collada): void {
    this.scenes[0].add(objeto.scene);
    var box = new THREE.BoxHelper(objeto.scene, 0xffff00);
    this.scenes[0].add(box);
    const geometry = new THREE.BoxGeometry(2, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    this.cube = new THREE.Mesh(geometry, material);
    //this.scene.add(this.cube);
    console.log('holaaa');
    console.log(objeto.scene.name);
    console.log(objeto.scene.type);
    //this.createScene(this.canvas)
    console.log(this.scenes);
  }

  public createSceneandLoad3d(canvas: HTMLCanvasElement, url: string): void {
    this.canvas[0] = canvas;
    this.createScene(this.canvas[0]);
    this.LoadCollada(url);
  }

  public scaleAndCenter(scene: THREE.Scene): void {
    let size: THREE.Vector3 = new THREE.Vector3();
    this.box.geometry.computeBoundingBox();
    this.box.geometry.boundingBox.getSize(size);
    console.log(size);

    let maxTam = Math.max(size.x, size.y, size.z);
    let escala = 5 / maxTam;
    console.log(scene.isObject3D);
    //
    //this.box.scale.set(5, 5, 5);
    //scene.scale.set(5, 5, 5)

    console.log(scene.isObject3D);

    let centro: THREE.Vector3 = new THREE.Vector3();
    this.box.geometry.boundingBox.getCenter(centro);
    console.log(centro);

    //let oo = <THREE.Object3D>scene;

    //console.log(oo.children);
    //this.box.geometry.boundingBox.setFromCenterAndSize(new THREE.Vector3(0, 0, 0),new THREE.Vector3(4, 4, 4));
    this.box.scale.setScalar(4);
    //scene.position.set((centro.x * escala) * -1, (centro.y * escala) * -1, (centro.z * escala) * -1);
    //scene.scale.set(escala,escala,escala)
    //let material = new THREE.MeshPhongMaterial({ color: 0xffffff});
    //var mesh = new THREE.Mesh(scene.children[0].children[2].m, material)

    scene.position.set(centro.x * -1, centro.y * -1, centro.z * -1);
    //this.scene.updateMatrix()
  }

  public LoadCollada(url: string): Collada {
    var loader = new ColladaLoader();
    var miobjeto: Collada = null;
    loader.load(
      url,
      (objeto) => {
        //miescena = objeto.scene
        miobjeto = objeto;
        //this.scenes[0].add(objeto.scene);
        //this.box = new THREE.BoxHelper(objeto.scene, 0xffff00);
        //this.scenes[0].add(this.box);
        //this.scaleAndCenter(objeto.scene);

        // Posiciona el centro del boundingbox en el centro de la escena.
        //this.box.geometry.boundingBox.setFromCenterAndSize(new THREE.Vector3(0, 0, 0), size);

        // Reposiciona el objeto al centro de la escena.
        //objeto.scene.position.set((centro.x * escala) * -1, (centro.y * escala) * -1, (centro.z * escala) * -1);

        //this.animate();
      },
      (progress: { loaded: number; total: number }) => {
        console.log((progress.loaded / progress.total) * 100 + '% loaded');
      },
      (error) => {
        console.error('An error happened');
      }
    );
    return miobjeto;
  }

  public load3dToScene(
    filename: string,
    scene: THREE.Scene,
    i: number
  ): Observable<string> {
    return new Observable((observer) => {
      this.is3dloaded = false;
      this.loadingProgressText = "0"
      let r = this.scenes.find((v, ii) => v.name == filename);

      if (r) {
        console.log('ya existe la escena');
        console.log(r.userData.camera.id);

        this.is3dloaded = true;
        this.actualindex = this.scenes.indexOf(r);
        //
        let aa: HTMLElement = r.userData.element;
        aa.style.zIndex = '10';
        //
        console.log('anterior ', this.lastElementOrbitControl);

        if (this.lastElementOrbitControl != aa) {
          this.lastElementOrbitControl.style.zIndex = '0';
          this.lastElementOrbitControl = aa;
        }
        this.resize();
        //let c:OrbitControls= r.userData.controls
        //c.domElement=this.canvas.nativeElement
        //c.saveState()
        observer.complete();
        observer.unsubscribe();
        observer.remove(observer);
        return;
      }

      this.actualindex = this.scenes.length;

      console.log('Creando nueva escena');
      console.log(this.actualindex);

      scene = this.simpleCreateSceneInCanvas(this.canvas, filename);

      //let urlfile = 'assets/' + this.lista.files[index].filename
      //let urlfile = 'assets/' + this.lista.files[0].filename
      if (filename.endsWith('.dae')) {
        let loader = new ColladaLoader(this.loadingManager);
        loader.load(
          'assets/' + filename,
          (objeto) => {
            //bjeto.scene.scale.multiply(new THREE.Vector3(-1,-1,-1))
            let dae: THREE.Scene = objeto.scene;

            dae.children.forEach((element) => {
              let gg: THREE.Mesh;
              //gg.isMesh
              //element<THREE.Mesh>
            });
            dae.traverse((child) => {
              let mesh = child as THREE.Mesh;
              if (mesh.isMesh) {
                let material = mesh.material as THREE.Material;
                // model does not have normals
                material.flatShading = true;
              }
            });

            this.is3dloaded = true;
            //dae.scale.x = dae.scale.y = dae.scale.z = 10.0;
            dae.updateMatrix();
            scene.add(objeto.scene);

            observer.next('loaded Collada');
            observer.complete();
            //this.box = new THREE.BoxHelper(objeto.scene, 0xffff00);
            //this.scenes[0].add(this.box);
            //this.scaleAndCenter(objeto.scene);

            // Posiciona el centro del boundingbox en el centro de la escena.
            //this.box.geometry.boundingBox.setFromCenterAndSize(new THREE.Vector3(0, 0, 0), size);

            // Reposiciona el objeto al centro de la escena.
            //objeto.scene.position.set((centro.x * escala) * -1, (centro.y * escala) * -1, (centro.z * escala) * -1);

            //this.animate();
          },
          (progress: { loaded: number; total: number }) => {
            console.log((progress.loaded / progress.total) * 100 + '% loaded');
            observer.next(
              (progress.loaded / progress.total) * 100 + '% loaded'
            );
          },
          (error) => {
            console.error('An error happened');
          }
        );
      }

      if (filename.endsWith('.glb')) {
        let gltfLoader = new GLTFLoader(this.loadingManager);
        gltfLoader.load(
          'assets/' + filename,
          (gltf) => {
            // Now the model has been loaded, we can add it to our instant_tracker_group
            this.is3dloaded = true;
            scene.add(gltf.scene);
            observer.next('loaded gltfa');
            observer.complete();
          },
          undefined,
          () => {
            console.log('An error ocurred loading the GLTF model');
          }
        );
      }

      if (filename.endsWith('.obj')) {
        let objLoader = new OBJLoader(this.loadingManager);
        objLoader.load(
          'assets/' + filename,
          (objeto) => {
            // Rota para ajustar Y up
            objeto.rotation.set(Math.PI * 1.5, 0, 0);
            objeto.updateMatrix();
            let size: THREE.Vector3 = new THREE.Vector3();
            let center: THREE.Vector3 = new THREE.Vector3();

            let box = new THREE.BoxHelper(objeto, 0xffff00);
            box.geometry.computeBoundingBox();
            box.geometry.boundingBox.getSize(size);

            let maxTam = Math.max(size.x, size.y, size.z);
            let escala = 5 / maxTam;

            objeto.scale.set(escala, escala, escala);

            box = new THREE.BoxHelper(objeto, 0xffff00);
            box.geometry.computeBoundingBox();
            box.geometry.boundingBox.getCenter(center);

            objeto.position.set(center.x * -1, center.y * -1, center.z * -1);

            objeto.castShadow = true;
            objeto.receiveShadow = true;

            //scene.add(box);
            objeto.children.forEach((mesh) => {
              let aa: THREE.Mesh = mesh as THREE.Mesh;

              //aa.material = new MeshStandardMaterial();
              //aa.material = new MeshNormalMaterial()
              aa.material = new MeshPhongMaterial({
                color: 0x999999,
                shininess: 0,
                specular: 0x222222,
              });
              aa.material.side = THREE.DoubleSide;
              aa.castShadow = true;
              aa.receiveShadow = true;
              //aa.material.flatShading = true
            });
            this.is3dloaded = true;

            scene.add(objeto);
            this.resize();
            observer.next('100');
            observer.complete();

            //let helper = new THREE.CameraHelper(dirLight.shadow.camera)
            //let helper = new THREE.Hel
            //scene.add(helper)
          },
          (progress: { loaded: number; total: number }) => {
            this.loadingProgressText = String(
              (progress.loaded / progress.total) * 100
            ).slice(0, 3);
            //console.log((progress.loaded / progress.total) * 100 + '% loaded');
            observer.next(this.loadingProgressText);
          },
          (error) => {
            console.error('An error happened');
          }
        );
      }
    });
  }

  public createScene2(
    g: CarouselComponent,
    gg: ElementRef<HTMLDivElement>,
    index: number
  ) {
    // create the scene
    let scene = new THREE.Scene();
    this.scenes.push(scene);
    console.log(g.slides.toArray());
    console.log(g.slides.toArray()[index].id);
    setTimeout(() => {
      console.log(document.getElementById(g.slides.toArray()[index].id));
      scene.userData.element = document.getElementById(
        g.slides.toArray()[index].id
      );

      //scene.userData.element = gg.nativeElement.firstElementChild

      console.log(scene.userData.element);

      let camera = new THREE.PerspectiveCamera(
        50,
        innerWidth / innerHeight,
        0.1,
        1000
      );
      camera.position.z = 6;
      scene.userData.camera = camera;

      let controls = new OrbitControls(
        scene.userData.camera,
        scene.userData.element
      );

      controls.minDistance = 1;
      controls.maxDistance = 50;
      controls.enablePan = false;
      controls.enableZoom = true;
      scene.userData.controls = controls;

      // soft white light
      let ambientlight = new THREE.AmbientLight(0xffffff, 0.5);

      scene.add(ambientlight);

      let dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
      dirLight.name = 'Dir. Light';
      dirLight.position.set(3, 10, 10);
      dirLight.castShadow = true;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 30;

      dirLight.shadow.mapSize.width = 512;
      dirLight.shadow.mapSize.height = 512;
      dirLight.shadow.radius = 2;
      dirLight.shadow.bias = -0.0005;
      scene.add(dirLight);

      let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      //directionalLight.castShadow = true
      //let directionalLights = new THREE.DirectionalLightShadow(camera);
      //directionalLight.position.set(1, 1, 0).normalize();
      directionalLight.position.set(1, -1, -1).normalize();
      //directionalLight.position.set(300, 250, -500).normalize();
      directionalLight.shadow.mapSize.width = 512;
      directionalLight.shadow.mapSize.height = 512;
      directionalLight.shadow.camera.near = 0.5;
      directionalLight.shadow.camera.far = 30;
      scene.add(directionalLight);

      //let dirGroup = new THREE.Group();
      //dirGroup.add(dirLight);
      //scene.add(dirGroup);

      let directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3);
      directionalLight2.position.set(0, 0, 5);
      scene.add(directionalLight2);

      /* this.load3dToScene(
        g.slides.toArray()[index].id,
        this.scenes[index]
      ).subscribe(() => {
        this.animate();
      }); */
    }, 300);
  }

  public createRenderer() {
    if (!document.getElementById('fullrendererCanvas')) {
      this.canvas = this.angularRenderer.createElement('canvas');
      this.angularRenderer.setProperty(this.canvas, 'id', 'fullrendererCanvas');
      this.angularRenderer.appendChild(document.body, this.canvas);
    } else {
      this.canvas.nativeElement = document.getElementById(
        'fullrendererCanvas'
      ) as HTMLCanvasElement;
      console.log('ya existe el full canvas');
    }

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas.nativeElement,
      antialias: true,
      preserveDrawingBuffer: false,
    });
    this.renderer.setClearColor(0xff00ff, 1);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.VSMShadowMap;

    //this.animate();
  }

  public clenanScene() {
    this.scenes[0].children.forEach((value) => {
      if (value.type == 'Group') {
        this.scenes[0].remove(value);
      }
    });
  }

  public showSnackbarLoading() {
    this.snackref = this._snackBar.openFromComponent(LoaderComponent);
  }

  public simpleCreateSceneInCanvas(
    canvas: ElementRef<HTMLCanvasElement>,
    scenaName: string
  ): THREE.Scene {
    /* if (!document.getElementById('fullrendererCanvas')) {
      this.canvas = this.angularRenderer.createElement('canvas');
      this.angularRenderer.setProperty(this.canvas, 'id', 'fullrendererCanvas');
      this.angularRenderer.appendChild(document.body, this.canvas);
    } else {
      this.canvas = document.getElementById(
        'fullrendererCanvas'
      ) as HTMLCanvasElement;
      console.log('ya existe el full canvas');
    } */
    this.canvas = canvas;
    //this.scenes = [];
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas.nativeElement,
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: false,
    });
    //this.renderer.setViewport(0,0,this.canvas.width, this.canvas.height)
    //this.renderer.setSize(contienecanvas.nativeElement.clientWidth, contienecanvas.nativeElement.clientHeight)
    //this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight,false)
    //this.renderer.setClearColor(0xff00ff, 1);
    //this.renderer.setPixelRatio(window.devicePixelRatio);
    //this.renderer.shadowMap.enabled = true;
    //this.renderer.shadowMap.type = THREE.VSMShadowMap;

    let scene = new THREE.Scene();
    this.scenes.push(scene);

    scene.name = scenaName;

    //scene.userData.element = this.canvas.nativeElement;
    let d = this.angularRenderer.createElement('div');
    this.angularRenderer.setProperty(d, 'id', scenaName);
    this.angularRenderer.setStyle(d, 'height', '100%');
    this.angularRenderer.setStyle(d, 'width', '100%');
    this.angularRenderer.setStyle(d, 'position', 'absolute');

    //this.angularRenderer.appendChild(canvas.nativeElement.parentElement, d);
    this.angularRenderer.insertBefore(
      canvas.nativeElement.parentElement,
      d,
      canvas.nativeElement
    );
    console.log('anterior___ ', this.lastElementOrbitControl);

    if (this.lastElementOrbitControl) {
      this.lastElementOrbitControl.style.zIndex = '1';
    }
    this.lastElementOrbitControl = d;
    this.lastElementOrbitControl.style.zIndex = '10';

    //scene.userData.element = this.renderer.domElement;
    scene.userData.element = d;
    //scene.userData.element = canvas.nativeElement.parentElement;

    let camera = new THREE.PerspectiveCamera(
      50,
      innerWidth / innerHeight,
      0.1,
      1000
    );
    camera.position.z = 8;

    scene.userData.camera = camera;

    let controls = new OrbitControls(
      scene.userData.camera,
      scene.userData.element
    );

    controls.minDistance = 1;
    controls.maxDistance = 50;
    controls.enablePan = false;
    controls.enableZoom = true;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 0.3;
    scene.userData.controls = controls;

    // soft white light
    let ambientlight = new THREE.AmbientLight(0xffffff, 0.5);

    scene.add(ambientlight);

    let dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
    dirLight.name = 'Dir. Light';
    dirLight.position.set(3, 10, 10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 30;

    dirLight.shadow.mapSize.width = 512;
    dirLight.shadow.mapSize.height = 512;
    dirLight.shadow.radius = 2;
    dirLight.shadow.bias = -0.0005;
    scene.add(dirLight);

    let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    //directionalLight.castShadow = true
    //let directionalLights = new THREE.DirectionalLightShadow(camera);
    //directionalLight.position.set(1, 1, 0).normalize();
    directionalLight.position.set(1, -1, -1).normalize();
    //directionalLight.position.set(300, 250, -500).normalize();
    directionalLight.shadow.mapSize.width = 512;
    directionalLight.shadow.mapSize.height = 512;
    directionalLight.shadow.camera.near = 0.5;
    directionalLight.shadow.camera.far = 30;
    scene.add(directionalLight);

    //let dirGroup = new THREE.Group();
    //dirGroup.add(dirLight);
    //scene.add(dirGroup);

    let directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3);
    directionalLight2.position.set(0, 0, 5);
    scene.add(directionalLight2);

    //resize
    const width = this.canvas.nativeElement.clientWidth;
    const height = this.canvas.nativeElement.clientHeight;
    const widthparent = this.canvas.nativeElement.parentElement.clientWidth;
    const heightparent = this.canvas.nativeElement.parentElement.clientHeight;

    let w = this.renderer.domElement.width;
    let h = this.renderer.domElement.height;
    console.log(w, h);
    console.log(widthparent, heightparent);

    //this.renderer.domElement.width = widthparent
    //this.renderer.domElement.height = heightparent

    //this.renderer.setSize(w, h, true);
    //this.renderer.setViewport(0,0,w, h)
    this.renderer.setSize(widthparent, heightparent, false);
    console.log(
      this.renderer.domElement.width,
      this.renderer.domElement.height
    );
    console.log(this.renderer.getSize(new THREE.Vector2()));
    //this.renderer.setViewport(0,0,widthparent,heightparent)
    // const widthparent =this.canvas.nativeElement.parentElement.clientWidth
    //const heightparent = this.canvas.nativeElement.parentElement.clientHeight
    this.scenes[this.actualindex].userData.camera.aspect =
      widthparent / heightparent;
    this.scenes[this.actualindex].userData.camera.updateProjectionMatrix();

    //console.log("555555555");
    //this.animate();

    return scene;
    /*
    this.load3dToScene(
      "avioneta.obj",
      scene
    ).subscribe(() => {
      this.animate();
    });
*/
    //
  }

  //public createEmpty(g: HTMLDivElement) {
  public createEmpty(g: CarouselComponent) {
    this.scenes = [];

    if (!document.getElementById('fullrendererCanvas')) {
      this.canvas = this.angularRenderer.createElement('canvas');
      this.angularRenderer.setProperty(this.canvas, 'id', 'fullrendererCanvas');
      this.angularRenderer.appendChild(document.body, this.canvas);
    } else {
      this.canvas.nativeElement = document.getElementById(
        'fullrendererCanvas'
      ) as HTMLCanvasElement;
      console.log('ya existe el full canvas');
    }

    console.log(g.slidesOutputData.slides);

    //for (let index = 0; index < g.slidesOutputData.slides.length; index++) {
    for (let index = 0; index < g.slides.length; index++) {
      let scene = new THREE.Scene();

      //console.log(g.slides.toArray()[index].id);
      //console.log(g.slidesOutputData.slides[index].id)
      //console.log(document.getElementById(g.slidesOutputData.slides[index].id))
      //this.angularRenderer
      // the element that represents the area we want to render the scene
      scene.userData.element = document.getElementById(
        g.slides.toArray()[index].id
      );
      //scene.userData.element = document.getElementById(g.slidesOutputData.slides[index].id);
      //scene.userData.element = g.slidesOutputData.slides[index].id

      let camera = new THREE.PerspectiveCamera(
        50,
        innerWidth / innerHeight,
        0.1,
        1000
      );
      camera.position.z = 6;
      scene.userData.camera = camera;

      let controls = new OrbitControls(
        scene.userData.camera,
        scene.userData.element
      );

      controls.minDistance = 1;
      controls.maxDistance = 50;
      controls.enablePan = false;
      controls.enableZoom = true;
      scene.userData.controls = controls; /**/

      /* const geometry = new THREE.BoxGeometry(5, 5, 5);
      let material = new THREE.MeshBasicMaterial({
        color: 0x00ff00,
        wireframe: true,
      });
      let cube = new THREE.Mesh(geometry, material);
      scene.add(cube); */

      // soft white light
      let ambientlight = new THREE.AmbientLight(0xffffff, 0.5);
      //light.position.z = 10;
      scene.add(ambientlight);

      /*let light = new THREE.SpotLight( 0xffffff, 1, 0, Math.PI / 5, 0.3 );
				light.position.set( 0, 15, 10 );
				light.target.position.set( 0, 0, 0 );

				light.castShadow = true;
				light.shadow.camera.near = 0.5;
				light.shadow.camera.far = 30;
				light.shadow.bias = 0.0001;

				light.shadow.mapSize.width = 512;
				light.shadow.mapSize.height = 512;

				scene.add( light );
*/
      let dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
      dirLight.name = 'Dir. Light';
      dirLight.position.set(3, 10, 10);
      dirLight.castShadow = true;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 30;

      dirLight.shadow.mapSize.width = 512;
      dirLight.shadow.mapSize.height = 512;
      dirLight.shadow.radius = 2;
      dirLight.shadow.bias = -0.0005;
      scene.add(dirLight);

      let dirGroup = new THREE.Group();
      dirGroup.add(dirLight);
      scene.add(dirGroup);

      /*var migeometry = new THREE.PlaneBufferGeometry( 200, 200 );
				var mimaterial = new THREE.MeshPhongMaterial( {
					color: 0x999999,
					shininess: 0,
					specular: 0x111111
				} );

				 var ground = new THREE.Mesh( migeometry, mimaterial );
				ground.rotation.x = -Math.PI/2;
				ground.scale.multiplyScalar( 3 );
				ground.castShadow = true;
				ground.receiveShadow = true;
				scene.add( ground ); */

      let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      //directionalLight.castShadow = true
      //let directionalLights = new THREE.DirectionalLightShadow(camera);
      directionalLight.position.set(1, 1, 0).normalize();
      //directionalLight.position.set(300, 250, -500).normalize();
      directionalLight.shadow.mapSize.width = 512;
      directionalLight.shadow.mapSize.height = 512;
      directionalLight.shadow.camera.near = 0.5;
      directionalLight.shadow.camera.far = 30;
      scene.add(directionalLight); /**/

      let directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3);
      //directionalLight.castShadow = true
      //let directionalLights = new THREE.DirectionalLightShadow(camera);
      directionalLight.position.set(1, 1, -1).normalize();
      //directionalLight.position.set(300, 250, -500).normalize();

      scene.add(directionalLight2);

      //Load external 3d
      if (index < this.lista.files.length) {
        //let objetoLoaded:Collada = this.LoadCollada("assets/"+this.lista.files[index].filename)
        //scene.add(objetoLoaded.scene)

        let filename = this.lista.files[index].filename;

        if (filename.endsWith('.dae')) {
          let loader = new ColladaLoader(this.loadingManager);
          loader.load(
            'assets/' + this.lista.files[index].filename,
            (objeto) => {
              //bjeto.scene.scale.multiply(new THREE.Vector3(-1,-1,-1))
              let dae: THREE.Scene = objeto.scene;

              dae.children.forEach((element) => {
                let gg: THREE.Mesh;
                //gg.isMesh
                //element<THREE.Mesh>
              });
              dae.traverse((child) => {
                let mesh = child as THREE.Mesh;
                if (mesh.isMesh) {
                  let material = mesh.material as THREE.Material;
                  // model does not have normals
                  material.flatShading = true;
                }
              });

              //dae.scale.x = dae.scale.y = dae.scale.z = 10.0;
              dae.updateMatrix();
              scene.add(objeto.scene);
              //this.box = new THREE.BoxHelper(objeto.scene, 0xffff00);
              //this.scenes[0].add(this.box);
              //this.scaleAndCenter(objeto.scene);

              // Posiciona el centro del boundingbox en el centro de la escena.
              //this.box.geometry.boundingBox.setFromCenterAndSize(new THREE.Vector3(0, 0, 0), size);

              // Reposiciona el objeto al centro de la escena.
              //objeto.scene.position.set((centro.x * escala) * -1, (centro.y * escala) * -1, (centro.z * escala) * -1);

              //this.animate();
            },
            (progress: { loaded: number; total: number }) => {
              console.log(
                (progress.loaded / progress.total) * 100 + '% loaded'
              );
            },
            (error) => {
              console.error('An error happened');
            }
          );
        }

        if (filename.endsWith('.glb')) {
          let gltfLoader = new GLTFLoader(this.loadingManager);
          gltfLoader.load(
            'assets/' + this.lista.files[index].filename,
            (gltf) => {
              // Now the model has been loaded, we can add it to our instant_tracker_group
              scene.add(gltf.scene);
            },
            undefined,
            () => {
              console.log('An error ocurred loading the GLTF model');
            }
          );
        }

        if (filename.endsWith('.obj')) {
          let objLoader = new OBJLoader(this.loadingManager);
          objLoader.load(
            'assets/' + this.lista.files[index].filename,
            (objeto) => {
              // Rota para ajustar Y up
              objeto.rotation.set(Math.PI * 1.5, 0, 0);
              objeto.updateMatrix();
              let size: THREE.Vector3 = new THREE.Vector3();
              let center: THREE.Vector3 = new THREE.Vector3();

              let box = new THREE.BoxHelper(objeto, 0xffff00);
              box.geometry.computeBoundingBox();
              box.geometry.boundingBox.getSize(size);

              let maxTam = Math.max(size.x, size.y, size.z);
              let escala = 5 / maxTam;

              objeto.scale.set(escala, escala, escala);

              box = new THREE.BoxHelper(objeto, 0xffff00);
              box.geometry.computeBoundingBox();
              box.geometry.boundingBox.getCenter(center);

              objeto.position.set(center.x * -1, center.y * -1, center.z * -1);

              objeto.castShadow = true;
              objeto.receiveShadow = true;

              //scene.add(box);
              objeto.children.forEach((mesh) => {
                let aa: THREE.Mesh = mesh as THREE.Mesh;

                //aa.material = new MeshStandardMaterial();
                //aa.material = new MeshNormalMaterial()
                aa.material = new MeshPhongMaterial({
                  color: 0x999999,
                  shininess: 0,
                  specular: 0x222222,
                });
                aa.material.side = THREE.DoubleSide;
                aa.castShadow = true;
                aa.receiveShadow = true;
                //aa.material.flatShading = true
              });
              console.log(objeto.children);
              scene.add(objeto);

              //let helper = new THREE.CameraHelper(dirLight.shadow.camera)
              //let helper = new THREE.Hel
              //scene.add(helper)
            },
            (progress: { loaded: number; total: number }) => {
              console.log(
                (progress.loaded / progress.total) * 100 + '% loaded'
              );
            },
            (error) => {
              console.error('An error happened');
            }
          );
        }
      }

      this.scenes.push(scene);
    }

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas.nativeElement,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setClearColor(0xff00ff, 1);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.VSMShadowMap;

    this.animate();
  }

  public captureImage(filename: string) {
    let d = document.createElement('img');
    document.body.appendChild(d);
    d.src = this.renderer.domElement.toDataURL();

    this.http
      .post('/api', d.src, {
        headers: { 'Content-type': 'multipart/form-data' },
        params: { filename: filename },
      })
      .subscribe((response) => {
        console.log(response);
      });
    /*this.http.get("/api",{headers:{"Content-type":"image/png"},params:{imagen:d.src}}).subscribe((response)=>{
      console.log(response)
    })*/
    /*  this.http.get("/api",{params:{"imagen":"jjjnnjxxx"}}).subscribe((response)=>{
      console.log(response)
    }) */

    /*  var link = document.createElement('a');
    if (typeof link.download === 'string') {
      document.body.appendChild(link); //Firefox requires the link to be in the body
      link.download = 'aaaaaaa.png';
      link.href = d.src;
      link.click();
      document.body.removeChild(link); //remove the link when done
    } else {
      //location.replace(uri);
    } */
  }

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    //console.log(canvas);
    //this.canvas[0] = canvas;
    this.inCarrousel = false;
    this.canvas = canvas;

    this.scenes = [];

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas.nativeElement,
      antialias: true,
      preserveDrawingBuffer: true,
      alpha: true,
    });

    //this.renderer.setClearColor(0xffffff, 1);
    //this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.VSMShadowMap;

    // create the scene
    let scene = new THREE.Scene();
    this.scenes.push(scene);
    //scene.userData.element = s

    let camera = new THREE.PerspectiveCamera(
      50,
      canvas.nativeElement.clientWidth / canvas.nativeElement.clientHeight,
      0.1,
      1000
    );
    camera.position.z = 6;

    scene.add(camera);
    this.camera = camera;

    //

    //camera.updateProjectionMatrix();
    this.controls = new OrbitControls(camera, this.renderer.domElement);
    //this.camera.lookAt(new THREE.Vector3(0,0,0))
    //this.camera.rotateY(Math.PI/3)
    // soft white light
    let ambientlight = new THREE.AmbientLight(0xffffff, 0.5);

    scene.add(ambientlight);

    let dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
    dirLight.name = 'Dir. Light';
    dirLight.position.set(3, 10, 10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 30;

    dirLight.shadow.mapSize.width = 512;
    dirLight.shadow.mapSize.height = 512;
    dirLight.shadow.radius = 2;
    dirLight.shadow.bias = -0.0005;
    scene.add(dirLight);

    let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    //directionalLight.castShadow = true
    //let directionalLights = new THREE.DirectionalLightShadow(camera);
    //directionalLight.position.set(1, 1, 0).normalize();
    directionalLight.position.set(1, -1, -1).normalize();
    //directionalLight.position.set(300, 250, -500).normalize();
    directionalLight.shadow.mapSize.width = 512;
    directionalLight.shadow.mapSize.height = 512;
    directionalLight.shadow.camera.near = 0.5;
    directionalLight.shadow.camera.far = 30;
    scene.add(directionalLight);

    //let dirGroup = new THREE.Group();
    //dirGroup.add(dirLight);
    //scene.add(dirGroup);

    let directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3);
    directionalLight2.position.set(0, 0, 5);
    scene.add(directionalLight2);

    const geometry = new THREE.BoxGeometry(5, 5, 5);
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      wireframe: true,
    });
    let cube = new THREE.Mesh(geometry, material);
    //scene.add(cube); /**/
    let box = new THREE.BoxHelper(cube, 0xffff00);
    //scene.add(box)

    this.animate();
  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        console.log('animate start rendering');
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          console.log('ok Content Loaded');
          this.render();
        });
      }

      window.addEventListener('resize', () => {
        console.log('ok Resize');
        this.resize();
      });
    });
  }

  public render(): void {
    //this.resize();

    //console.log("ok render llamado")
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });

    if (this.inCarrousel) {
      this.canvas.nativeElement.style.transform = `translateY(${window.scrollY}px)`;

      this.renderer.setClearColor(0xffffff);
      this.renderer.setScissorTest(false);
      this.renderer.clear();

      this.renderer.setClearColor(0xe0e0e0);
      this.renderer.setScissorTest(true);

      this.scenes.forEach((escena) => {
        //console.log(escena)
        //escena.children[0].rotation.y = Date.now() * 0.001;

        // get the element that is a place holder for where we want to
        // draw the scene
        if (escena.userData.element) {
          var element: HTMLDivElement = escena.userData.element;
          let rect = element.getBoundingClientRect();

          // check if it's offscreen. If so skip it
          if (
            rect.bottom < 0 ||
            rect.top > this.renderer.domElement.clientHeight ||
            rect.right < 0 ||
            rect.left > this.renderer.domElement.clientWidth
          ) {
            return; // it's off screen
          }

          // set the viewport
          var width = rect.right - rect.left;
          var height = rect.bottom - rect.top;
          var left = rect.left;
          var bottom = this.renderer.domElement.clientHeight - rect.bottom;

          this.renderer.setViewport(left, bottom, width, height);
          this.renderer.setScissor(left, bottom, width, height);

          let camera = escena.userData.camera;

          camera.aspect = width / height; // not changing in this example
          camera.updateProjectionMatrix();

          //scene.userData.controls.update();
          camera.lookAt(escena.position);

          this.renderer.render(escena, camera);
        }
      });
    } else {
      //console.log(this.frameId)
      //this.cube.rotation.x += 0.01;
      //this.cube.rotation.y += 0.01;
      //this.controls.update();

      /* this.scenes.forEach((escena) => {
        this.renderer.render(escena, escena.userData.camera);
      }) */
      let camera: THREE.PerspectiveCamera = this.scenes[this.actualindex]
        .userData.camera;

      const width = this.canvas.nativeElement.clientWidth;
      const height = this.canvas.nativeElement.clientHeight;
      camera.aspect = width / height; // not changing in this example
      //camera.updateProjectionMatrix();
      //this.scenes[this.actualindex].userData.controls.update();
      /* if (this.actualindex == 3) {
        let c: OrbitControls = this.scenes[this.actualindex].userData.controls;
        c.autoRotate = true;
        c.update();
      } */
      this.scenes[this.actualindex].userData.controls.update();
      //this.renderer.clear();
      this.renderer.render(
        this.scenes[this.actualindex],
        this.scenes[this.actualindex].userData.camera
      );
    }
  }

  public resize(): void {
    //const width = window.innerWidth;
    //const height = window.innerHeight;
    //this.canvas.width = this.canvas.parentElement.clientWidth;
    //this.canvas.style.height = this.canvas.parentElement.clientHeight.toString();
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    const widthparent = this.canvas.nativeElement.parentElement.clientWidth;
    const heightparent = this.canvas.nativeElement.parentElement.clientHeight;
    this.renderer.setSize(widthparent, heightparent, false);
    this.scenes[this.actualindex].userData.camera.aspect =
      widthparent / heightparent;
    this.scenes[this.actualindex].userData.camera.updateProjectionMatrix();

    if (
      this.canvas.nativeElement.width !== widthparent ||
      this.canvas.nativeElement.height !== heightparent
    ) {
      //this.renderer.setSize(this.canvas.nativeElement.width, this.canvas.nativeElement.height, false);
      //this.scenes[0].userData.camera.aspect = width / height;
      //this.scenes[0].userData.camera.updateProjectionMatrix();

      console.log(this.canvas.nativeElement.clientWidth);
      console.log(widthparent);
      console.log(this.renderer.getSize(new THREE.Vector2()));

      console.log('resize');
    }
  }
}

export interface ListaFiles {
  files: [
    {
      filename: string;
      id: string;
      img: string;
    }
  ];
}
