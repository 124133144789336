import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CarouselComponent,
  OwlOptions,
  SlidesOutputData,
} from 'ngx-owl-carousel-o';
import { ThreeService } from '../three.service';

@Component({
  selector: 'app-lista3d',
  templateUrl: './lista3d.component.html',
  styleUrls: ['./lista3d.component.css'],
})
export class Lista3dComponent implements OnInit {
  public lista: ListaFiles;

  @ViewChild('owlLista3d') owlLista3d: CarouselComponent;

  slidesStore: any = {};
  selected3d:string ='avioneta.obj'

  categoriesOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    center: true,
    margin: 10,
    dotsSpeed: 300,
    autoHeight:true,
    autoWidth:true,
    mergeFit:true,
    merge:true,
    responsive: {
      0: {
        items: 8,
        loop: true,
      },
      400: {
        items: 8,
        loop: true,
      },
      740: {
        items: 20,
        loop: true,
      },
      940: {
        items: 20,
        loop: true,
      },
    },
  };


  constructor(private http: HttpClient, private three: ThreeService) {}

  public load3dhere(filename:string, i:number){
    //this.three.clenanScene()
    //this.three.showSnackbarLoading()
    this.three.load3dToScene(filename, this.three.scenes[i], i).subscribe(
      (value) => {
        //this.three.snackref.instance.text= value
      },
      (error) => {
        console.log('error al cargar el fichero 3d ', error);
      },
      () => {
        //this.three.snackref.dismiss()
        console.log('3d Loaded');
        //this.three.animate();
      }
    );
  }

  ngOnInit(): void {

    // load lista de 3d from json desde el servicio
    this.three.LoadListFiles().subscribe(() => {
      this.slidesStore = this.three.lista;
    });
  }

  ngAfterViewInit() {
    this.owlLista3d.initialized.subscribe(
      (slides) => {
        console.log(slides);
      },
      (error) => {},
      () => {}
    );
  }
}

export interface ListaFiles {
  filename: string;
}
