import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Loader } from 'three';
import { GalleryComponent } from '../gallery/gallery.component';
import { Lista3dComponent } from '../lista3d/lista3d.component';
import { LoaderComponent } from '../loader/loader.component';
import { PresentComponent } from '../present/present.component';
import { ThreeService } from '../three.service';

@Component({
  selector: 'app-simple3d',
  templateUrl: './simple3d.component.html',
  styleUrls: ['./simple3d.component.css'],
})
export class Simple3dComponent implements OnInit {


  @ViewChild("canvasInComponent") canvasInComponent:ElementRef<HTMLCanvasElement>
  @ViewChild("contieneCanvas") contieneCanvas:ElementRef<HTMLDivElement>

  constructor(public three: ThreeService,

    ) {}

  public is3dloaded: boolean = false;
  loadingProgressText: string = '';

  ngOnInit(): void {
    this.is3dloaded = this.three.is3dloaded;
  }

  consoleprint(){
    console.log(this.three.scenes);

  }

  ngAfterViewInit() {



    this.three.scenes=[]
    //let escena = this.three.simpleCreateSceneInCanvas(this.canvasInComponent);
    //this.three.render()
    this.three.canvas = this.canvasInComponent
    //this.three.showSnackbarLoading()
    let observable = this.three.load3dToScene('avioneta.obj', null, 0)
    observable.subscribe(
      (value) => {

        //this._snackBar._openedSnackBarRef.instance.data.message = "value"
        // snackref.containerInstance.snackBarConfig.data.message = this.loadingProgressText
        //this.three.snackref.instance.text= value
        this.loadingProgressText = value;
      },
      (error) => {
        console.log('error al cargar el fichero 3d ', error);
      },
      () => {
        console.log('3d Loaded');
        //this.three.snackref.dismiss()
        this.is3dloaded = true;
        //this.three.render()
        this.three.animate();

      }
    );



  }
}
