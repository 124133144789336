import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ThreeService } from '../three.service';

@Component({
  selector: 'app-images-generator',
  templateUrl: './images-generator.component.html',
  styleUrls: ['./images-generator.component.css'],
})
export class ImagesGeneratorComponent implements OnInit {
  @ViewChild('micanvas') micanvas: ElementRef<HTMLCanvasElement>;

  asyncGreeting: Promise<string>;

  constructor(private threeService: ThreeService) {}

  filename: string;

  ngOnInit(): void {}

  clickprint() {
    this.threeService.captureImage('none');
  }
  ngAfterViewInit() {
    this.threeService.LoadListFiles().subscribe((value) => {
      this.threeService.createScene(this.micanvas);
      for (let index = 0; index < this.threeService.lista.files.length; index++) {
        this.threeService
          .load3dToScene(
            this.threeService.lista.files[index].filename,
            this.threeService.scenes[0],0
          )
          .subscribe(
            (value) => {
              console.log(value);
            },
            (error) => {},
            () => {
              console.log('completo');
              this.threeService.animate();
              this.threeService.captureImage(
                this.threeService.lista.files[index].filename
              );
              console.log(
                this.threeService.scenes[0].children.filter(
                  (g) => g.type == 'Group'
                )
              );

              this.threeService.scenes[0].remove.apply(
                this.threeService.scenes[0],
                this.threeService.scenes[0].children.filter(
                  (g) => g.type == 'Group'
                )
              );
            }
          );
        console.log(value);
      }
    });

    /*this.threeService.LoadListFiles().toPromise().then(value=>{
      this.threeService.createScene(this.micanvas.nativeElement)

      console.log(value)
    })*/
  }
}
