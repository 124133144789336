import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {ThreeService} from "../three.service";

@Component({
  selector: 'app-three',
  templateUrl: './three.component.html',
  styleUrls: ['./three.component.css']
})
export class ThreeComponent implements OnInit {

  @ViewChild('rendererCanvas', {static: true})
  public rendererCanvas: ElementRef<HTMLCanvasElement>;
  url: string;

  constructor( private threeService: ThreeService) { }

  ngOnInit(): void {
    //this.threeService.createScene(this.rendererCanvas)raf22031.dae
    //this.threeService.myload3dm(this.rendererCanvas.nativeElement,"assets/abb_irb52_7_120.dae")
    //this.threeService.createSceneandLoad3d(this.rendererCanvas.nativeElement,"assets/abb_irb52_7_120.dae")
    //this.threeService.myload3dm(this.rendererCanvas.nativeElement,"assets/raf22031.dae")
    //this.threeService.animate()
  }

  runAnimate():void{
    console.log("gggg")
    this.threeService.animate()
  }

}
