<!--
<div class="box">

  <app-lista3d></app-lista3d>

  <ng-container *ngIf="!this.three.is3dloaded">
    <div
      style="display: flex; width: 100%; height: 100%; position: fixed; align-items: center; justify-content: center; ">
      <mat-spinner mode="determinate" diameter="20" strokeWidth="3" value="{{this.three.loadingProgressText}}">
      </mat-spinner>
      <div> Loading...{{this.three.loadingProgressText}}%</div>
    </div>
  </ng-container>


  <div class="row header">
  </div>
  <div class="row contentenido">

    <div id="contieneCanvas" style="height: 100%; display: flex;">
      <canvas style="height: inherit; width: 100%;" id="canvasInComponent" #canvasInComponent></canvas></div>

  </div>
  <div class="row footer">
    footer
  </div>
</div> -->

<div style="display: flex; flex-direction: column; height: 100%;">

  <div style="margin: 10px;">
    <h3>//////// Gallery </h3>
    <div class="">
      <app-lista3d></app-lista3d>
    </div>
  </div>

  <div id="contieneCanvas" class="" style="flex: auto; contain: strict;">
    <ng-container *ngIf="!this.three.is3dloaded">
      <div
        style="display: flex; width: 100%; height: 100%; position: fixed; align-items: center; justify-content: center; ">
        <mat-spinner mode="determinate" diameter="20" strokeWidth="3" value="{{this.three.loadingProgressText}}">
        </mat-spinner>
        <div> Loading...{{this.three.loadingProgressText}}%</div>
      </div>
    </ng-container>
    <canvas style="height: 100%; width: 100%;" id="canvasInComponent" #canvasInComponent></canvas>
  </div>

  <div class="">
    <div>

    </div>
  </div>

</div>
